import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WorkExperienceComponent } from './work-experience.component';



@NgModule({
  declarations: [WorkExperienceComponent],
  imports: [
    CommonModule
  ],
  exports: [WorkExperienceComponent]
})
export class WorkExperienceModule { }
